import { ProductsListWidget } from '@app/products/ProductListWidget';
import {
  Page,
  Avatar,
  CardInfo,
  Categories,
  SectionDetails,
  SectionRow,
} from '@teddly/teddly-ui-components';
import { useEffect, useState } from 'react';
import { getVendorsData } from '@services/vendors/api';
import { AlgoliaIndexes, InstantSearchWrapper } from '@config/algolia';
import { getVendorSubCategoriesData } from '@services/categories/api';
import { CategoryItem } from '@interfaces';
import { useVendorContext } from '@context/VendorContext';
import { usePageLayoutContext } from '@context/PageLayoutContext';
import styles from './VendorPage.module.scss';
import { BuildingsIcon } from '@components/Atoms/Icons/BuildingsIcon';
import { useRoutesContext } from '@context/RoutesContext';
import { useChannelContext } from '@context/ChannelContext';
import { useHomePageContext } from '@context/HomePageContext';
import { hiddenImageOnError } from '@utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { getServerTranslations } from '@utils/i18n';

//Get ServerSideProps instead of getStaticProps because it's dynamic route "stores/[vendorSlug]"
export async function getServerSideProps({ locale }: { locale: string }) {
  return { props: { ...(await getServerTranslations(locale, ['common'])) } };
}

export default function VendorWidget(): JSX.Element {
  const { router: appRouter, navigateTo } = useRoutesContext();

  const { isChannelSelected, selectedChannel } = useChannelContext();

  const { vendorSlug, category: categorySlug, q: query } = appRouter.query;
  const { vendor, setVendor } = useVendorContext();
  const [categories, setCategories] = useState([]);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(true);
  const { setLoading } = usePageLayoutContext();
  const { bodyFooterInformationProps } = useHomePageContext();
  const { t } = useTranslation('common', { keyPrefix: 'stores' });

  const router = useRouter();

  const vendorChannelRout = `?channel=${vendor?.channel?.id}`;

  const vendorSlugWithChannel = `${vendorSlug}${vendorChannelRout}`;

  const storeRout = `/stores/${vendorSlugWithChannel}`;

  const getStoreCategoryRout = (category: CategoryItem) => {
    return `${storeRout}&category=${category.slug}`;
  };

  useEffect(() => {
    if (vendorSlug) {
      const vendorChannel = vendor?.channel?.id;
      if (vendorChannel && !router.query?.channel) {
        const newUrl = router.asPath + vendorChannelRout;
        router.push(newUrl);
      }
      setLoading(true);
      getVendorsData(vendorSlug as string).then((newVendors) => {
        if (newVendors.length > 0) {
          setLoading(true);
          setVendor(newVendors[0]);
          getVendorSubCategoriesData(vendorSlug as string).then(
            (newCategories) => {
              setCategories(newCategories);
              setIsCategoriesLoading(false);
              setLoading(false);
            },
          );
        }
      });
    }
  }, [vendorSlug]);

  let currentCategory = null;
  const categoriesView = categories?.map((category: CategoryItem) => {
    if (category.slug == categorySlug) currentCategory = category;
    return {
      name: category.name,
      icon: category?.imgSrc ? (
        <img
          alt={category.name}
          src={category.imgSrc}
          onError={hiddenImageOnError}
        />
      ) : null,
      onClick: () => {
        navigateTo({
          route: getStoreCategoryRout(category),
          options: {
            shallow: true,
          },
        });
      },
      onDelete: () =>
        navigateTo({
          route: storeRout,
          options: { shallow: true },
        }),
      active:
        categorySlug == category.slug ||
        category.categories?.findIndex(
          (subCategory) => subCategory.slug === categorySlug,
        ) >= 0,
      categories: category.categories?.map((subCategory) => {
        if (subCategory.slug == categorySlug) currentCategory = subCategory;
        return {
          name: subCategory.name,
          icon: subCategory.imgSrc,
          onClick: () => {
            navigateTo({
              route: getStoreCategoryRout(subCategory),
              options: {
                shallow: true,
              },
            });
          },
        };
      }),
    };
  });

  const queryExcludeChannel =  query?.indexOf('?') !== -1 ? (query as string)?.substring(0, query?.indexOf('?')) : query;
  return (
    <Page.Body
      {...bodyFooterInformationProps}
      categories={<Categories categories={categoriesView} />}>
      {vendor &&
        vendor?.channel?.id === selectedChannel?.id &&
        isChannelSelected && (
          <CardInfo
            alignItemsCenter
            avatar={
              <Avatar imageSrc={vendor.logo} name={vendor.name} size="medium" />
            }
            title={vendor.name}
            showAvatar>
            {vendor.address && (
              <SectionDetails title={t('sub-title')}>
                {vendor.address && (
                  <SectionRow
                    icon={<BuildingsIcon />}
                    title={t('address')}
                    value={vendor.address}
                  />
                )}
              </SectionDetails>
            )}
          </CardInfo>
        )}
      {vendor &&
        vendor?.channel?.id === selectedChannel?.id &&
        isChannelSelected && (
          <>
            <ProductsListWidget
              waitLoadingForCategories={isCategoriesLoading}
              className={styles.vendorProductsList}
              title={
                vendor?.name +
                (currentCategory
                  ? ` ${currentCategory?.name}`
                  : queryExcludeChannel
                    ? ` ${queryExcludeChannel} Search Result`
                    : ' products')
              }
              fromCategory={currentCategory?.slug}
              fromVendor={vendor?.slug}
              showFilters
              query={query ? (query as string) : null}
              // clickToLoad={true}
            />
          </>
        )}
    </Page.Body>
  );
}

export const VendorPage = () => {
  return (
    <InstantSearchWrapper indexName={AlgoliaIndexes.PRODUCTS}>
      <VendorWidget />
    </InstantSearchWrapper>
  );
};
